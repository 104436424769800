import React from 'react'

import AboutContributors from '../components/aboutContributors'
import AboutTeam from '../components/aboutTeam'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Seo from '../components/seo'

const NAV_ITEMS = [
  {
    name: 'Team',
    path: '#team',
  },
  {
    name: 'Contributors',
    path: '#contributors',
  },
]

const About = () => (
  <Layout
    subHeaderNavItems={NAV_ITEMS}
    subHeaderNavTitle={'About Podlove'}
  >
    <Seo title="About" />
    <Hero
      copy="Podlove is an initiative to improve the overall technical infrastructure for podcasting. Podlove is both an network for developers to discuss features and agree on standards as well as an incubator for software and file formats under the Podlove name."
      headline="About Podlove"
      showIllustration={false}
    />
    <p
      className="
        px-4
        md:px-0
        max-w-xl
        text-blue-800
      "
    >Podlove is an initiative born in Germany but we are open to collaborate with anybody and to promote standards worldwide. If you have any further questions and/or want to contribute to Podlove, <a className="text-turquoise-500 font-semibold" href="/contact">contact us</a>.</p>
    <AboutTeam />
    <AboutContributors />
    <div
      className="
        px-4
        md:px-0
        text-blue-800
        mt-20
        mb-40
        max-w-xl
      "
    >
      <p>We also like to thank everybody who has contributed by sending bug reports, providing bug fixes, making feature requests or giving any other form of feedback. This project lives from the interaction with the community.</p>
    </div>
  </Layout>
)

export default About