import React from 'react'

import { ABOUT_TEAM_DATA } from '../content/about'
import Animation from '../elements/animation'

const AboutTeam = () => (
  <div
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-4
      md:px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
    id="team"
  >
    <h2
      className="
        font-black
        leading-snug
        text-4xl
        md:text-5xl
        max-w-2xl
        z-10
      "
    >The Team behind Podlove</h2>
    <p
      className="
        text-xl
        max-w-2xl
        z-10
      "
    >Podlove is an Open Source project and therefore has a lot of contributors that are working to make the software better. The main organizers of the project are these four people. </p>
    <div
      className="
        md:grid
        md:grid-cols-3
        md:gap-24
        font-sans
        md:px-12
        py-20
        md:w-screen
        max-w-7xl
        text-left
        tracking-wide
      "
    >
      <Animation />
      { ABOUT_TEAM_DATA.team.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            bg-gray-200
            flex
            flex-col
            px-4
            md:px-10
            py-10
            mb-20
            md:mb-0
            text-center
            max-w-max
            z-10
          "
        >
          <div
            className="
              overflow-hidden
              bg-blue-400
              rounded-full
              h-24
              mx-auto
              -mt-24
              mb-10
              w-24
            "
          >
            <img src={item.image ? `../images/${item.image}` : '../images/icons/user--white.png'} />
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <h4
            className="
              font-mono
              pb-4
              text-l
            "
          >{item.subtitle}</h4>
          <p>{item.copy}</p>
          <div
            className="
              mt-auto
            "
          >
            { item.social && item.social.length && 
              <div
                className="
                  flex
                  space-x-2
                "
              >
                { item.social.map(social => (
                  <a
                    key={JSON.stringify(social.label)}
                    href={social.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className="
                        bg-blue-400
                        hover:bg-blue-500
                        rounded-md
                        h-8
                        mt-10
                        w-8
                      "
                    >
                      <img src={social.icon} />
                    </div>
                  </a>
                ))}
              </div>
            }
          </div>
        </div>
      )}
    </div>
  </div>
)

export default AboutTeam
