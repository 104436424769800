export const ABOUT_TEAM_DATA = {
  team: [
    {
      copy: 'Tim is founder and project coordinator of Podlove. He is defining the requirements for Podlove Publisher and Podlove Web Player and works on the Podlove Specifications. Under his podcast label “Metaebene Personal Media” Tim produces a variety of podcasts and has 18+ years of radio and podcast experience and contributes podcast scene.',
      image: '../images/tim-pritlove.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/timpritlove',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/timpritlove',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://metaebene.me/',
        },
      ],
      subtitle: 'Founder & Evangelist',
      title: 'Tim Pritlove',
    },
    {
      copy: 'Alex is a front-end developer and has joined the project in 2017 working on version 4 of Podlove Web Player. He normally works on large business applications featuring Angular. With a special fetish for new JavaScript technologies he loves to play around with new frameworks.',
      image: '../images/alexander-heimbuch.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/zusatzstoff',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/alexander-heimbuch',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://zusatzstoff.org/',
        },
      ],
      subtitle: 'Developer',
      title: 'Alexander Heimbuch',
    },
    {
      copy: 'Eric is chief software architect and main contributor for the Podlove Publisher and oversees the release process and the technical roadmap. He has kicked off Podlove together with Tim by producing the first version of the Publisher in 2012 and has been working on it ever since.',
      image: '../images/eric-teubert.jpeg',
      social: [
        {
          label: 'Instagram',
          icon: '../images/icons/instagram--white.png',
          url: 'https://www.instagram.com/ericteubert/',
        },
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/ericteubert',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/eteubert',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://ericteubert.de/',
        },
      ],
      subtitle: 'Developer',
      title: 'Eric Teubert',
    },
    {
      copy: 'Michaela is front-end developer and has joined the project in 2016 working on the Podlove Subscribe Button and other UI aspects. She is founder of Geil, Danke web development agency.',
      image: '../images/michaela-lehr.jpeg',
      social: [
        {
          label: 'Instagram',
          icon: '../images/icons/instagram--white.png',
          url: 'https://www.instagram.com/fischaelameer/',
        },
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/fischaelameer',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/Fischaela',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://geildanke.com/',
        },
      ],
      subtitle: 'Developer',
      title: 'Michaela',
    },
    {
      copy: 'Dirk is a C++ developer and mainly develops CAD software. To learn new things, he participates in the Podlove project since 2020. He helps especially in the development of the publisher.',
      image: '../images/dirk.jpg',
      social: [
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/dnkbln',
        },
      ],
      subtitle: 'Developer',
      title: 'Dirk',
    },
    {
      copy: 'Martin is a Berlin based podcaster and workshop tutor. He focuses on history and education in his work and uses Podlove almost since the start of his Podcast "Staatsbürgerkunde" in 2012.',
      image: '../images/martin.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/nitramred',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://daselbst.de/',
        },
      ],
      subtitle: 'Podcaster',
      title: 'Martin Fischer',
    },
  ]
}

export const ABOUT_CONTRIBUTORS_DATA = {
  team: [
    {
      copy: 'Dominik Wagner is an iOS and backend developer, Co-Founder and CEO of the Game and app studio Coding Monkeys, and worked on UIKit@Apple for a while. He supports the Podlove Publisher and Web Player open source development whenever help is needed.',
      image: '../images/dom.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/monkeydom',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'http://rant.monkeydom.de/',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://codingmonkeys.de/',
        },
      ],
      subtitle: 'Developer',
      title: 'Dom',
    },
    {
      copy: 'Sven is a graphic artist contributing a lot of the graphical artwork we need for the Podlove Publisher and is also behind the visual design of the next generation of the Podlove Web Player. His creative approach to user interfaces is providing us with a lot of ideas and future direction for the development of the software. Have a look at his portfolio at Graphorama.',
      image: '../images/sven-sedivy.jpeg',
      social: [
        {
          label: 'Instagram',
          icon: '../images/icons/instagram--white.png',
          url: 'https://www.instagram.com/graphorama/',
        },
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/graphorama',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'http://graphorama.de/',
        },
      ],
      subtitle: 'Graphic Designer',
      title: 'Sven Sedivy',
    },
    {
      copy: 'Ben is a Ruby and CoffeeScript developer and has joined the project in 2014 working on the Podlove Subscribe Button. He is also one of the founders of the Podigee podcast hosting service.',
      image: '../images/ben-zimmer.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/zeroathome',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/benzimmer',
        },
      ],
      subtitle: 'Developer',
      title: 'Benjamin Zimmer',
    },
    {
      copy: 'Alex has joined the project in 2013 and has made a variety of significant contributions to various Podlove projects. As an all-round web-developer he worked in close collaboration with Eric on upcoming releases.',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://github.com/chemiker',
        },
      ],
      subtitle: 'Developer',
      title: 'Alexander Lüken',
    },
    {
      copy: 'Dennis is a PHP and WordPress expert and has contributed some significant hackery to make the Podlove Publisher work in some crazy edge cases. Somebody to call when the house is burning.',
      image: '../images/dennis-morhardt.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/gglnx',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/gglnx',
        },
      ],
      subtitle: 'Developer',
      title: 'Dennis Morhardt',
    },
    {
      copy: 'Juri is a JavaScript developer and joined the project in 2013. He worked on the prototype of the Podlove Web Player 3.',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/line_o',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/line-o',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'http://line-o.de/',
        },
      ],
      subtitle: 'Developer',
      title: 'Juri Leino',
    },
    {
      copy: 'Alexandra is a front-end developer and joined the project in 2014. She worked on styling and behaviour of the prototype of the Podlove Web Player 3.',
      image: '../images/alexandra.png',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/plutonik8',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/plutonik-a',
        },
      ],
      subtitle: 'Developer',
      title: 'Alexandra von Criegern',
    },
    {
      copy: 'Frank is a JavaScript developer and has joined the project in 2013 assisting the developement of the Podlove Web Player.',
      image: '../images/frank-hase.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/kambfhase',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/kambfhase',
        },
      ],
      subtitle: 'Developer',
      title: 'Frank Hase',
    },
    {
      copy: 'Simon has been taking over the original code for the Podlove Web Player and has worked on code and design of the version 2 of the player. Simon is also very active supporting the podcast scene with his extensive contributions to the Shownotes project.',
      image: '../images/simon-waldherr.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/simonwaldherr',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/simonwaldherr',
        },
        {
          label: 'Website',
          icon: '../images/icons/web--white.png',
          url: 'https://simonwaldherr.de',
        },
      ],
      subtitle: 'Developer',
      title: 'Simon Waldherr',
    },
    {
      copy: 'Gerrit has been contributing the very first incarnation of the Podlove Web Player which was used to launch the first version of the Podlove Web Player.',
      image: '../images/gerrit-van-aaken.jpeg',
      social: [
        {
          label: 'Twitter',
          icon: '../images/icons/twitter--white.png',
          url: 'https://twitter.com/gerritvanaaken',
        },
        {
          label: 'Github',
          icon: '../images/icons/github--white.png',
          url: 'https://github.com/gerritvanaaken',
        },
      ],
      subtitle: 'Developer',
      title: 'Gerrit van Aaken',
    },
  ]
}